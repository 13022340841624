$primary: #f3bf38;
$secondary: #ff873f;
$success: #f8ce61;
$info: #528aff;
$warning: #ff542b;
$danger: #b71407;
$light: #e8e8e8;
$dark: #444444;

$text-muted: rgba(0, 0, 0, 0.6);
$link-hover-decoration: none;

:export {
  primary: $primary;
  secondary: $secondary;
  textMuted: $text-muted
}
