// dashboard default
.dashboard {
  display       : flex;
  flex-direction: column;
  height        : 100%;

  .navbar {
    color           : $primary;
    background-color: white;
    position        : fixed;
    z-index         : 1;
    top             : 48px;
    width           : 100%;
    border-bottom   : 1px solid #ccc;
    padding         : 0;
    display         : flex;
    flex-direction  : row;

    .navbar-logo {
      text-align  : center;
      line-height : 47px;
      width       : 58px;
      border-right: 1px solid #ccc;
      overflow    : hidden;
      transition  : all .3s;

      img {
        width: 32px
      }
    }

    .navbar-content {
      flex          : 1;
      display       : flex;
      flex-direction: row;
      color         : black;
    }
  }

  .content {
    margin-top: 96px;
    height    : calc(100% - 96px);
    ;
    display       : flex;
    flex-direction: row;

    .siderbar {
      overflow        : hidden;
      width           : 58px;
      border-right    : 1px solid #ccc;
      transition      : all .3s;
      display         : flex;
      flex-direction  : column;
      background-color: white;

      .menu {
        flex-grow: 1;

        .active {
          color: $primary;
        }
      }

      .bottom {
        text-align    : center;
        padding-bottom: 10px;
      }
    }

    .body {
      flex                        : 1;
      display                     : flex;
      flex-direction              : column;
      overflow                    : hidden;
      //overflow-y: auto;
      //-webkit-overflow-scrolling: touch;
    }


  }


}


// extend menu
.dashboard.extend {
  .navbar {
    .navbar-logo {
      width: 200px;
    }
  }

  .content {
    .siderbar {
      width: 200px;
    }
  }
}

.listitem-active {
  color: red;
  background-color: lightcoral;
}

/* ------------------------------------------------
                  Mobile css
------------------------------------------------ */
@media (max-width: 767px) {
  .milk-nav {
    .dashboard.extend {
      .navbar {
        .navbar-logo {
          width: 0;
        }

        .navbar-content {
          display: inherit;
        }
      }

      .content {
        .siderbar {
          top: 145px;
        }
      }
    }
  }

  .dashboard {
    .navbar {
      .navbar-logo {
        width: 0;
      }
    }

    .content {
      .siderbar {
        position: absolute;
        top     : 96px;
        bottom  : 0;
        z-index : 1;
        width   : 0;
      }

      .body>div>.container-fluid {
        padding-left : 0;
        padding-right: 0;
        overflow     : hidden;
      }
    }
  }

  // extend menu
  .dashboard.extend {
    .navbar {
      .navbar-content {
        display: none;
      }
    }
  }
}