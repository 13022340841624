.control {
  position: absolute;
  right: 20px;
  top: 20px;
}

.d3 {
  * {
    fill: #696969;
    font-size: 6px;
  }

  .fill-white {
    fill: white;
  }

  .stroke-white {
    stroke: white;
  }

  .stroke-light {
    stroke: #d1d1d1;
    stroke-width: 0.5;
  }

  .highlight {
    fill: #bc7730;
  }

  .text-data {
    font-size: 0.5rem;
  }
}

/* green light animation*/
.e-sensor-green {
  animation: green 1s infinite;
  fill: green;
}

@keyframes green {
  0% {
    fill: green;
  }
  50% {
    fill: #289c08;
  }
  100% {
    fill: green;
  }
}

/* red light animation*/
.e-sensor-red {
  animation: red 1s infinite;
  fill: red;
}

@keyframes red {
  0% {
    fill: red;
  }
  50% {
    fill: white;
  }
  100% {
    fill: red;
  }
}

/* rotate animation*/
.rotate {
  animation: spin 3s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
